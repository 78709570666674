import { createTheme } from '@mui/material/styles';
import FontNotoSansBold from './assets/fonts/NotoSansJP-Bold.ttf';
import FontNotoSansRegular from './assets/fonts/NotoSansJP-Regular.ttf';
import FontNotoSansMedium from './assets/fonts/NotoSansJP-Medium.ttf';
import FontHiraginoKakuGothicPro from './assets/fonts/HiraginoKakuGothicProW3.otf';
import FontInterRegular from './assets/fonts/Inter/static/Inter-Regular.ttf';
import { useAssetUrlCreator } from './helpers/assetUrlCreator';

const CreateTheme = ({
  Widget = { },
  Page = { },
}) => {

  const { createAssetUrl } = useAssetUrlCreator();
  
  const theme = createTheme({
    text: {

    },
    typography: {
      fontFamily: 'Noto Sans Regular',
      fontWeight: 400,
      lineHeight: 'normal',
      h1: {
        fontFamily: 'Noto Sans Bold',
        fontStyle: 'normal',
        lineHeight: 'normal',
        color: '#333',
        fontWeight: 700,
      },
      body1: {
        fontFamily: 'Noto Sans Medium',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontWeight: 500,
        color: '#333',
      },
      body2: {
        fontFamily: 'Noto Sans Regular',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontWeight: 400,
        color: '#333',
      },
      bold: {
        fontFamily: 'Noto Sans Bold',
        fontStyle: 'normal',
        lineHeight: 'normal',
        color: '#333',
        fontWeight: 700,
      },
      button: {
        fontFamily: 'Noto Sans Medium',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    palette: {
      primary: {
        main: '#333',
        strong: '#000',
        border: '#CCC',
      },
      secondary: {
        main:  '#008DDE',
        bgButton: '#FFA825',
        white: '#FFF',
        gray: '#8F8F8F',
        note: '#FF4040',
        dark: '#7A7A7A',
        light: '#4798F6',
        lightGray: '#a5a5a5',
      },
      error: {
        main: '#FF4040',
      },
      background: {
        main: '#FFF',
        second: '#fafafa',
        third: '#008DDE',
        fourth: '#FFA825',
        fifth: '#CCC',
      },
      page: {
        main: Page.MainColor || '#008DDE',
        mainText: Page.MainColor || '#008DDE',
        contrastText: '#FFF',
      },
      buttonPrimary: {
        main: Page.MainColor || '#008DDE',
        light: Page.MainColor,
        dark: Page.MainColor,
        contrastText: '#FFF',
      },
      buttonSecond: {
        main: Page.DecisionButtonBgColor || '#FFA825',
        light: Page.DecisionButtonBgColor,
        dark: Page.DecisionButtonBgColor,
        contrastText: '#FFF',
      },
      widget: {
        main: Widget.BgColor || '#008DDE',
        contrastText: Widget.TextColor || '#000000'
      },
      buttonWidget: {
        main: Widget.ButtonBgColor || '#FFF',
        light: Widget.ButtonBgColor,
        dark: Widget.ButtonBgColor,
        contrastText: Widget.ButtonTextColor || '#000000',
      },
      buttonBasic: {
        main: '#FFF',
        light: '#FFF',
        dark: '#FFF',
        contrastText: '#333',
      }
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Noto Sans Bold';
            src: local("Noto Sans Bold"), url(${createAssetUrl(FontNotoSansBold)}) format("truetype");
          }
          
          @font-face {
            font-family: 'Noto Sans Regular';
            src: local("Noto Sans Regular"), url(${createAssetUrl(FontNotoSansRegular)}) format("truetype");
          }
          
          @font-face {
            font-family: 'Noto Sans Medium';
            src: local("Noto Sans Regular"), url(${createAssetUrl(FontNotoSansMedium)}) format("truetype");
          }
          
          @font-face {
            font-family: 'Hiragino Kaku Gothic Pro';
            src: local("Hiragino Kaku Gothic Pro"), url(${createAssetUrl(FontHiraginoKakuGothicPro)}) format("opentype");
          }
          
          @font-face {
            font-family: 'Inter Regular';
            src: local("Inter Regular"), url(${createAssetUrl(FontInterRegular)}) format("truetype");
          }
        `,
      },
      MuiScopedCssBaseline: {
        styleOverrides: {
          root: `
            @font-face {
              font-family: 'Noto Sans Bold';
              src: local("Noto Sans Bold"), url(${createAssetUrl(FontNotoSansBold)}) format("truetype");
            }
            
            @font-face {
              font-family: 'Noto Sans Regular';
              src: local("Noto Sans Regular"), url(${createAssetUrl(FontNotoSansRegular)}) format("truetype");
            }
            
            @font-face {
              font-family: 'Noto Sans Medium';
              src: local("Noto Sans Regular"), url(${createAssetUrl(FontNotoSansMedium)}) format("truetype");
            }
            
            @font-face {
              font-family: 'Hiragino Kaku Gothic Pro';
              src: local("Hiragino Kaku Gothic Pro"), url(${createAssetUrl(FontHiraginoKakuGothicPro)}) format("opentype");
            }
            
            @font-face {
              font-family: 'Inter Regular';
              src: local("Inter Regular"), url(${createAssetUrl(FontInterRegular)}) format("truetype");
            }
          `,
        },
      },
      MuiTypography: {
        styleOverrides: {
          fontStyle: 'normal',
          lineHeight: 'normal',
          overline: {
            color: '#FFFFFF',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '2px',
            fontFamily: 'Noto Sans Medium',
            fontWeight: 500,
            boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.25)',
            fontStyle: 'normal',
            lineHeight: 'normal',
            '&:hover': {
              opacity: 0.9,
            },
            '&:disabled': {
              cursor: 'not-allowed',
              pointerEvents: 'unset',
            },
          },
        },
      },
    },
    spacing: 4,
    breakpoints: {
      values: {
        xs: 0,
        ss: 300,
        se: 350,
        sm: 600,
        md: 900,
        lgs: 1200,
        lg: 1280,
        xl: 1536,
        xxl: 1920,
      },
    },
  });

  return theme;
}

export default CreateTheme;
