import { parse, addDays } from "date-fns";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useSettings } from "../contexts/SettingsContext";
import { formatDate } from "./dateFormatter";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRoomAndPerson } from "./roomAndPerson";
import { useRoomAndPersonFormatter } from "./roomAndPersonFormatter";

export const useSearchCondition = (
  initializeWithDefault = false,
) => {
  const parameterKey = {
    checkInDate: 'date',
    nights: 'nights',
    roomCount: 'rooms',
    paxCount: 'pax',
    childA70Count: 'child_a',
    childB50Count: 'child_b',
    childC30Count: 'child_c',
    childDNoneCount: 'child_d',
    childECount: 'child_e',
    childFCount: 'child_f',
    planType: 'plan_type',
    roomType: 'room_type',
    mealCondition: 'meal_condition',
    preferanceCondition: 'prefer_condition',
  };

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { valueSettings, hotelSettings, isLoading: isSettingsLoading } = useSettings();
  const maxCheckinDate = addDays(new Date(), valueSettings.MaxCheckinDate);
  const [ isSearchConditionDirty, setIsSearchConditionDirty ] = useState(true);

  // 検索条件オブジェクトの生成
  const checkinDateParam = parse(searchParams.get(parameterKey.checkInDate), 'yyyy-MM-dd', new Date());
  const stayDaysParam = Number(searchParams.get(parameterKey.nights));
  const roomCountParam = Number(searchParams.get(parameterKey.roomCount));
  const adultCountParam = Number(searchParams.get(parameterKey.paxCount));
  const upperElementaryCountParam = Number(searchParams.get(parameterKey.childA70Count)) || 0;
  const lowerElementaryCountParam = Number(searchParams.get(parameterKey.childB50Count)) || 0;
  const toddlerWithMealsAndBeddingCountParam = Number(searchParams.get(parameterKey.childC30Count)) || 0;
  const toddlerWithMealsOnlyCountParam = Number(searchParams.get(parameterKey.childDNoneCount)) || 0;
  const toddlerWithBeddingOnlyCountParam = Number(searchParams.get(parameterKey.childECount)) || 0;
  const toddlerWithoutMealsAndBeddingCountParam = Number(searchParams.get(parameterKey.childFCount)) || 0;

  const [isDayuse, setIsDayuse] = useState(initializeWithDefault ? false : searchParams.get(parameterKey.planType) === '1');
  const [checkinDate, setCheckinDate] = useState(
    initializeWithDefault || Number.isNaN(checkinDateParam.getTime()) || checkinDateParam < new Date() ?
      new Date() :
      maxCheckinDate < checkinDateParam ? maxCheckinDate : checkinDateParam);
  const [stayDays, setStayDays] = useState(
    initializeWithDefault                              ? 1 :
    isDayuse                                           ? 0 :
    Number.isNaN(stayDaysParam) || stayDaysParam === 0 ? 1 :
    valueSettings.MaxNights < stayDaysParam            ? valueSettings.MaxNights :
                                                         stayDaysParam);
  const { roomAndPersonCounts, setRoomAndPersonCounts } = useRoomAndPerson({
    roomCount: initializeWithDefault || Number.isNaN(roomCountParam) || roomCountParam === 0 ? 1 :
               valueSettings.MaxRooms < roomCountParam                                       ? valueSettings.MaxRooms :
                                                                                               roomCountParam,
    adultCount: initializeWithDefault || Number.isNaN(adultCountParam) || adultCountParam === 0 ? 1 :
                valueSettings.MaxPax < adultCountParam                                          ? valueSettings.MaxPax :
                                                                                                  adultCountParam,
    upperElementaryCount: initializeWithDefault                            ? 0 :
                          valueSettings.MaxPax < upperElementaryCountParam ? valueSettings.MaxPax :
                                                                             upperElementaryCountParam,
    lowerElementaryCount: initializeWithDefault                            ? 0 :
                          valueSettings.MaxPax < lowerElementaryCountParam ? valueSettings.MaxPax :
                                                                             lowerElementaryCountParam,
    toddlerWithMealsAndBeddingCount: initializeWithDefault                                       ? 0 :
                                     valueSettings.MaxPax < toddlerWithMealsAndBeddingCountParam ? valueSettings.MaxPax :
                                                                                                   toddlerWithMealsAndBeddingCountParam,
    toddlerWithMealsOnlyCount: initializeWithDefault                                 ? 0 :
                               valueSettings.MaxPax < toddlerWithMealsOnlyCountParam ? valueSettings.MaxPax :
                                                                                       toddlerWithMealsOnlyCountParam,
    toddlerWithBeddingOnlyCount: initializeWithDefault                                   ? 0 :
                                 valueSettings.MaxPax < toddlerWithBeddingOnlyCountParam ? valueSettings.MaxPax :
                                                                                           toddlerWithBeddingOnlyCountParam,
    toddlerWithoutMealsAndBeddingCount: initializeWithDefault                                          ? 0 :
                                        valueSettings.MaxPax < toddlerWithoutMealsAndBeddingCountParam ? valueSettings.MaxPax :
                                                                                                         toddlerWithoutMealsAndBeddingCountParam,
  });
  const { format : formatRoomAndPerson } = useRoomAndPersonFormatter();
  const [roomTypes, setRoomTypes] = useState(initializeWithDefault ? [] : searchParams.getAll(parameterKey.roomType));
  const [mealConditions, setMealConditions] = useState(initializeWithDefault ? [] : searchParams.getAll(parameterKey.mealCondition));
  const [smokingTypes, setSmokingTypes] = useState(initializeWithDefault ? [] : searchParams.getAll(parameterKey.preferanceCondition));

  const createSearchConditionUrlParameter = () => {
    return createSearchConditionUrlParameterByValue({
      isDayuse,
      checkinDate,
      stayDays,
      roomAndPersonCounts,
      roomTypes,
      mealConditions,
      smokingTypes,
    });
  }

  const createSearchConditionUrlParameterByValue = ({
    isDayuse,
    checkinDate,
    stayDays,
    roomAndPersonCounts,
    roomTypes,
    mealConditions,
    smokingTypes,
  }) => {
    const result = createSearchParams({});
    result.set(parameterKey.planType, isDayuse ? '1' : '0');
    result.set(parameterKey.checkInDate, formatDate(checkinDate, 'yyyy-MM-dd'));
    result.set(parameterKey.nights, isDayuse ? '0' : stayDays.toString());
    result.set(parameterKey.roomCount, roomAndPersonCounts.roomCount?.toString() || '1');
    result.set(parameterKey.paxCount, roomAndPersonCounts.adultCount?.toString() || '1');

    if (roomAndPersonCounts.upperElementaryCount) {
      result.set(parameterKey.childA70Count, roomAndPersonCounts.upperElementaryCount.toString());
    }
    if (roomAndPersonCounts.lowerElementaryCount) {
      result.set(parameterKey.childB50Count, roomAndPersonCounts.lowerElementaryCount.toString());
    }
    if (roomAndPersonCounts.toddlerWithMealsAndBeddingCount) {
      result.set(parameterKey.childC30Count, roomAndPersonCounts.toddlerWithMealsAndBeddingCount.toString());
    }
    if (roomAndPersonCounts.toddlerWithMealsOnlyCount) {
      result.set(parameterKey.childDNoneCount, roomAndPersonCounts.toddlerWithMealsOnlyCount.toString());
    }
    if (roomAndPersonCounts.toddlerWithBeddingOnlyCount) {
      result.set(parameterKey.childECount, roomAndPersonCounts.toddlerWithBeddingOnlyCount.toString());
    }
    if (roomAndPersonCounts.toddlerWithoutMealsAndBeddingCount) {
      result.set(parameterKey.childFCount, roomAndPersonCounts.toddlerWithoutMealsAndBeddingCount.toString());
    }

    for (const roomType of roomTypes) {
      result.set(parameterKey.roomType, roomType);
    }

    for (const mealCondition of mealConditions) {
      result.set(parameterKey.mealCondition, mealCondition);
    }

    for (const somkingType of smokingTypes) {
      result.set(parameterKey.preferanceCondition, somkingType);
    }

    return result;
  }

  const resetSearchConditionFromUlrPatameter = () => {
    setStayDays(
      initializeWithDefault                              ? 1 :
      isDayuse                                           ? 0 :
      Number.isNaN(stayDaysParam) || stayDaysParam === 0 ? 1 :
      valueSettings.MaxNights < stayDaysParam            ? valueSettings.MaxNights :
                                                           stayDaysParam
    );
    setCheckinDate(
      initializeWithDefault || Number.isNaN(checkinDateParam.getTime()) || checkinDateParam < new Date() ?
      new Date() :
      maxCheckinDate < checkinDateParam ? maxCheckinDate : checkinDateParam
    );
    setRoomAndPersonCounts({
      roomCount: initializeWithDefault || Number.isNaN(roomCountParam) || roomCountParam === 0 ? 1 :
                  valueSettings.MaxRooms < roomCountParam                                       ? valueSettings.MaxRooms :
                                                                                                  roomCountParam,
      adultCount: initializeWithDefault || Number.isNaN(adultCountParam) || adultCountParam === 0 ? 1 :
                  valueSettings.MaxPax < adultCountParam                                          ? valueSettings.MaxPax :
                                                                                                    adultCountParam,
      upperElementaryCount: initializeWithDefault                            ? 0 :
                            valueSettings.MaxPax < upperElementaryCountParam ? valueSettings.MaxPax :
                                                                                upperElementaryCountParam,
      lowerElementaryCount: initializeWithDefault                            ? 0 :
                            valueSettings.MaxPax < lowerElementaryCountParam ? valueSettings.MaxPax :
                                                                                lowerElementaryCountParam,
      toddlerWithMealsAndBeddingCount: initializeWithDefault                                       ? 0 :
                                        valueSettings.MaxPax < toddlerWithMealsAndBeddingCountParam ? valueSettings.MaxPax :
                                                                                                      toddlerWithMealsAndBeddingCountParam,
      toddlerWithMealsOnlyCount: initializeWithDefault                                 ? 0 :
                                  valueSettings.MaxPax < toddlerWithMealsOnlyCountParam ? valueSettings.MaxPax :
                                                                                          toddlerWithMealsOnlyCountParam,
      toddlerWithBeddingOnlyCount: initializeWithDefault                                   ? 0 :
                                    valueSettings.MaxPax < toddlerWithBeddingOnlyCountParam ? valueSettings.MaxPax :
                                                                                              toddlerWithBeddingOnlyCountParam,
      toddlerWithoutMealsAndBeddingCount: initializeWithDefault                                          ? 0 :
                                          valueSettings.MaxPax < toddlerWithoutMealsAndBeddingCountParam ? valueSettings.MaxPax :
                                                                                                            toddlerWithoutMealsAndBeddingCountParam,
    });
  }

  useEffect(() => {
    if (!isSettingsLoading) {
      resetSearchConditionFromUlrPatameter();
      setIsSearchConditionDirty(false);
    }
  }, [isSettingsLoading]);

  return { 
    searchCondition: {
      isDayuse,
      checkinDate,
      stayDays,
      roomAndPersonCounts,
      roomTypes,
      mealConditions,
      smokingTypes,
    },
    setIsDayuse,
    setCheckinDate,
    setStayDays,
    setRoomAndPersonCounts,
    setRoomTypes,
    setMealConditions,
    setSmokingTypes,
    createSearchConditionUrlParameter,
    createSearchConditionUrlParameterByValue,
    stayDayItems: [...Array(valueSettings.MaxNights)].map((_, i) => {
      return {
        value: i + 1,
        text: t('nights', {day: i + 1}),
      }
    }),
    roomTypeItems: hotelSettings.SearchSettings.RoomTypeList.map((ele) => {
      return {
        value: ele.RoomCategory,
        text: ele.RoomTypeName,
      }
    }),
    mealConditionItems: [
      { value: '5', text: t('meal_condition.with_dinner') },
      { value: '6', text: t('meal_condition.with_breakfast') },
      { value: '7', text: t('meal_condition.full_board') },
      { value: '8', text: t('meal_condition.half_board') },
      { value: '9', text: t('meal_condition.late_night_meal') },
      { value: '10', text: t('meal_condition.a_meal_only') },
      { value: '11', text: t('meal_condition.no_meal') },
      { value: '12', text: t('meal_condition.with_lunch') },
      { value: '31', text: t('meal_condition.other_meals') },
    ],
    smokingTypeItems: [
      { value: '1', text: t('room_feature.no_smoking') },
      { value: '2', text: t('room_feature.smoking') },
    ],
    formatRoomAndPerson: formatRoomAndPerson(roomAndPersonCounts),
    isSearchConditionDirty,
  };
}
