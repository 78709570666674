import { createContext, useContext, useEffect, useState } from "react";
import { apiClient } from "../helpers/apiClient";
import { createSearchParams } from "react-router-dom";

/*
*
*/
const SettingsContext = createContext({});

const initializeHotelSettings = {
  HotelId: '',
  HotelName: '',
  WebHotelId: '',
  HotelDomain: '',
  DayuseSettings: {
    Dayuse: false,
  },
  ColorSettings: {
    Widget: {
      BgColor: '',
      TextColor: '',
      ButtonBgColor: '',
      ButtonTextColor: '',
    },
    Page: {
      MainColor: '',
      DecisionButtonBgColor: '',
    },
  },
  SearchSettings: {
    RoomTypeList: [],
  },
  LogoImgList: {
    PicturePath: '',
    PictureName: '',
    FileHash: '',
  },
};

const initializeGroupSettings = {
  GroupId: '',
  GroupName: '',
  HotelSettings: {
    HotelSelect: false,
    HotelList: [],
  },
};

const initializeValueSettings = {
  MaxCheckinDate: 366,
  MaxNights: 10,
  MaxRooms: 10,
  MaxPax: 10,
  MinCheckinTime: '15:00',
  MaxCheckinTime: '22:00',
  MaxCCExpiryYear: 10,
  CardTokenRequestUrl: '',
  PlanListDispPlans: 2,
  PlanListDispRooms: 1,
  RoomListDispPlans: 2,
  RoomListDispRooms: 1,
  PaymentErrorCodes_InvalidCardInformation: [],
  PaymentErrorCodes_ExceedingCreditLimit: [],
  PaymentErrorCodes_AuthenticationFailure: [],
};

const initializeMessages = {
  TermsAndConditions: '',
};

export const useSettings = () => useContext(SettingsContext);

export const SettingsContextProvider = ({
  children,
  client = apiClient,
}) => {

  const [ hotelSettings, setHotelSettings ] = useState(initializeHotelSettings);
  const [ groupSettings, setGroupSettings ] = useState(initializeGroupSettings);
  const [ valueSettings, setValueSettings ] = useState(initializeValueSettings);
  const [ messages, setMessages ] = useState(initializeMessages);
  const [ hasError, setHasError ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  const fetchSettings = (hotelId, groupId) => {
    client
      .get('/Settings?hotelId=' + (hotelId || '') + (groupId ? '&groupId=' + groupId : ''))
      .then(res => {
        if (res.data.BookingEngineSettings) {
          setHotelSettings(res.data.BookingEngineSettings);
        }
        if (res.data.BookingEngineGroupSettings) {
          setGroupSettings(res.data.BookingEngineGroupSettings);
        }
        if (res.data.ValueSettings) {
          setValueSettings(res.data.ValueSettings);
        }
        if (res.data.Messages) {
          setMessages(res.data.Messages);
        }
      })
      .catch(res => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const createHotelIdUrlParameter = (optionalSearchParams) => {
    const searchParams = optionalSearchParams || createSearchParams({});
    searchParams.append('h_id', hotelSettings.HotelId);
    return searchParams;
  }

  return (
    <SettingsContext.Provider
      value={{
        hotelSettings,
        groupSettings,
        valueSettings,
        messages,
        hasError,
        isLoading,
        fetchSettings,
        createHotelIdUrlParameter,
      }}>
      { children }
    </SettingsContext.Provider>
  );

}